import { Box, Flex, Heading, Text } from '@chakra-ui/react'

type HeaderProps = {
  title: React.ReactNode
  description?: React.ReactNode
}
const Header = ({ title, description }: HeaderProps) => {
  return (
    <Flex padding={'1rem 1rem'} h='50px' alignItems='center' pb={4}>
      <Box
        bgColor='yellow.500'
        rounded={'md'}
        w='100px'
        mr='1rem'
        justifyContent={'center'}
      >
        <Text textAlign={'center'} variant={'body-bold'}>
          Beta
        </Text>
      </Box>
      <Flex alignItems={'center'} justifyContent='center'>
        <Heading
          fontSize='20px'
          color='primary'
          lineHeight='38px'
          fontWeight='semibold'
          letterSpacing='-0.1px'
        >
          {title}
        </Heading>
        {description && (
          <Text ml='1rem' as='i' textColor={'gray.800'} fontWeight='semibold'>
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

export default Header
