import { useContext } from 'react'

import { AuthContext } from 'contexts'

import { appletListUnion } from 'config/applets'

export const appletGroupList = [
  'covid-19',
  'covid-epid-data',
  'moderna',
  'booster-demand-forecast',
  'cardio',
  'conference-data',
  'influenza',
  'rsv',
  'diagnostics',
  'ieg-policy-surveillance',
  'research-power-index',
  'pad',
  'biorisk',
  'internal-tooling',
  'rna',
  'pnh',
  'ida-360',
  'obesity-360',
] as const
export type appletGroupListUnion = (typeof appletGroupList)[number]

export const appletGroupCategories = [
  'disease',
  'custom',
  'business',
  'diagnostics',
] as const
export type appletGroupCategoriesUnion = (typeof appletGroupCategories)[number]

export type GroupT = 'Airfinity One ID' | 'Cardiometabolic' | 'Other'

export type AppletGroupConfig = {
  key: appletGroupListUnion
  name: string
  description: string
  category: appletGroupCategoriesUnion
  order: number
  applets: appletListUnion[]
  showApplets: boolean
  hidden?: boolean
  group?: GroupT
}

export const appletGroups: Record<appletGroupListUnion, AppletGroupConfig> = {
  'covid-19': {
    key: 'covid-19',
    name: 'COVID-19',
    description:
      'A complete 360 view on the progression of the COVID-19 pandemic, the political responses, and the landscape of vaccines and therapeutics.',
    category: 'disease',
    order: 1,
    applets: ['covid-science360', 'covid-commercial', 'epidemiology'],
    showApplets: true,
    group: 'Airfinity One ID',
  },
  rsv: {
    key: 'rsv',
    name: 'RSV',
    description:
      'A complete 360 view on seasonal RSV and the latest science, competitive insights, and market analysis for relevant vaccines and therapeutics.',
    category: 'disease',
    order: 2,
    applets: ['rsv-science360', 'rsv-commercial', 'rsv-epidemiology'],
    showApplets: true,
    group: 'Airfinity One ID',
  },

  influenza: {
    key: 'influenza',
    name: 'Influenza',
    description:
      'A complete 360 view on seasonal Influenza and the latest science, competitive insights, and market analysis for relevant vaccines and therapeutics.',
    category: 'disease',
    order: 3,
    applets: [
      'influenza-science360',
      'influenza-commercial',
      'influenza-epidemiology',
    ],
    showApplets: true,
    group: 'Airfinity One ID',
  },
  'ida-360': {
    key: 'ida-360',
    name: 'IDA - Infectious Disease Analytics',
    description:
      'A 360 view of clinical development across all infectious diseases; identify opportunities and track changes with our comprehensive database of candidates, trials, and approvals.',
    category: 'disease',
    order: 4,
    applets: ['ida-360'],
    showApplets: false,
    group: 'Airfinity One ID',
  },
  biorisk: {
    key: 'biorisk',
    name: 'Biorisk',
    description:
      'Monitor pathogenic threats with real time epidemiology tracking, analysis, and more.',
    category: 'disease',
    order: 5,
    applets: ['biorisk'],
    showApplets: false,
    group: 'Airfinity One ID',
  },
  'obesity-360': {
    key: 'obesity-360',
    name: 'Obesity',
    description:
      'A complete 360 view of the rapidly evolving anti-obesity therapy landscape, including competitive insights, market impact analyses, and associated disease outcomes forecasts.',
    category: 'disease',
    order: 6,
    applets: [
      'obesity-predictive-analytics',
      'obesity',
      'obesity-commercial',
      'obesity-epidemiology',
    ],
    showApplets: true,
    group: 'Cardiometabolic',
  },

  cardio: {
    key: 'cardio',
    name: 'Cardio',
    description:
      'A complete 360 view on drugs in clinical development for a range of Cardiovascular diseases.',
    category: 'disease',
    order: 7,
    applets: ['cardio-science360'],
    showApplets: true,
    group: 'Cardiometabolic',
  },
  'covid-epid-data': {
    key: 'covid-epid-data',
    name: 'COVID-19 Epid',
    description:
      'COVID-19 epidemiology data split by demographics as well as variants prevalence data.',
    category: 'custom',
    order: 8,
    applets: ['covid-epid-data'],
    showApplets: false,
    group: 'Other',
  },
  diagnostics: {
    key: 'diagnostics',
    name: 'Diagnostics',
    description:
      'A complete 360 view on diagnostics for COVID-19. Explore the latest science, policies, and track their use.',
    category: 'diagnostics',
    order: 10,
    applets: ['diagnostics-science360'],
    showApplets: true,
    group: 'Other',
  },
  moderna: {
    key: 'moderna',
    name: 'Moderna',
    description:
      'Overview of the COVID-19 vaccine market, split by region, income band, and country.',
    category: 'custom',
    order: 11,
    applets: [
      'moderna-science360',
      'moderna-commercial',
      'moderna-epidemiology',
    ],
    showApplets: true,
    group: 'Other',
  },
  'booster-demand-forecast': {
    key: 'booster-demand-forecast',
    name: 'COVID-19 Booster Forecasts',
    description:
      'Overview of the COVID-19 vaccine market, split by region, income band, and country.',
    category: 'custom',
    order: 12,
    applets: ['booster-demand-forecast'],
    showApplets: false,
    group: 'Other',
  },
  'conference-data': {
    key: 'conference-data',
    name: 'Cardio Conference Reports',
    description: 'Conference reports generated by Airfinity.',
    category: 'custom',
    order: 13,
    applets: ['conference-data'],
    showApplets: false,
    group: 'Other',
  },
  'ieg-policy-surveillance': {
    key: 'ieg-policy-surveillance',
    name: 'IEG Policy Surveillance',
    description:
      'Surveillance tool that scans hundreds of public data sources, multiple times per day, for regulatory changes based on Real World Data. Precise filtering, expert insight, and more.',
    category: 'custom',
    order: 14,
    applets: ['ieg-policy-surveillance'],
    showApplets: false,
    group: 'Other',
  },
  'research-power-index': {
    key: 'research-power-index',
    name: 'Research Power Index',
    description:
      'Compare the research output across countries within the field of non-human synthetic biology.',
    category: 'custom',
    order: 15,
    applets: ['research-power-index'],
    showApplets: false,
    group: 'Other',
  },
  pad: {
    key: 'pad',
    name: 'Pandemic Antiviral Discovery',
    description:
      'A complete 360 view of the antiviral treatment landscape in coronaviruses, orthomyxoviruses, and paramyxoviruses, including the latest science and funding efficacy analysis.',
    category: 'custom',
    order: 16,
    applets: ['pad'],
    showApplets: false,
    group: 'Other',
  },
  'internal-tooling': {
    key: 'internal-tooling',
    name: 'Internal Tooling',
    description:
      'Custom app for Airfinity Employees where the Engineering team does live testing of new features or internal tools.',
    category: 'custom',
    order: 17,
    applets: ['internal-tooling'],
    showApplets: false,
    group: 'Other',
  },
  rna: {
    key: 'rna',
    name: 'RNA',
    description:
      'A complete 360 view on RNA Therapeutics through the entire clinical development process.',
    category: 'custom',
    order: 18,
    applets: ['rna'],
    showApplets: false,
    group: 'Other',
  },
  pnh: {
    key: 'pnh',
    name: 'PNH',
    description:
      'A complete 360 view on drugs in clinical development for the PNH disease.',
    category: 'disease',
    order: 19,
    applets: ['pnh'],
    showApplets: false,
    group: 'Other',
  },
}

export const useAppletGroupFromApplet = (appletKey: appletListUnion) => {
  const { appletGroupsUserCanSee } = useContext(AuthContext)

  return Object.values(appletGroupsUserCanSee).find((appletGroup) =>
    appletGroup.applets.includes(appletKey)
  )
}
