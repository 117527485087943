import { removeMarkdown } from 'utils/markdonw'
import {
  ThumbsUp,
  ThumbsDown,
  Copy,
  CheckmarkOutline,
} from '@carbon/icons-react'
import {
  Button,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  PopoverFooter,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { useState } from 'react'
import useFeedback from './useFeedback'
import { ChatHistoryT } from '../types'

const TextFeedback = ({
  sendFeedback,
}: {
  sendFeedback: (value: string) => void
}) => {
  // Step 1: Create a state variable to store the textarea value
  const [text, setText] = useState('')

  // Step 2: Handle the change in textarea input
  const handleChange = (event: any) => {
    setText(event.target.value)
  }
  function handleFeedback() {
    sendFeedback(text)
  }
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant={'ghost'} fontWeight='normal'>
          Feedback
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent borderRadius={'xl'} p='1rem'>
          <PopoverArrow />
          <PopoverHeader borderBottom={'none'}>Feedback</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Text fontSize={'sm'} mb='1rem'>
              Write your feedback about the answer provided.
            </Text>
            <Textarea fontSize={'sm'} value={text} onChange={handleChange} />
          </PopoverBody>
          <PopoverFooter border='none'>
            <Button variant={'yellow'} onClick={handleFeedback}>
              Submit
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const FeedbackBar = ({
  content,
  isUser,
  isInternal,
  chatHistory,
}: {
  content: string
  isUser: boolean
  isInternal: boolean
  chatHistory: ChatHistoryT
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const [selectedFeedback, setSelectedFeedback] = useState('')
  const { mutate: sendFeedback } = useFeedback({
    onSuccess: () => console.log('done'),
    onError: (e) => console.error('Error', e),
  })
  if (isUser || isInternal) return null

  function copyToClipboard() {
    navigator.clipboard.writeText(removeMarkdown(content))
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 5000)
  }

  function clearFeedback() {
    setTimeout(() => setSelectedFeedback(''), 3000)
  }

  function goodResponse() {
    sendFeedback({ context: JSON.stringify(chatHistory), feedback: 'good' })
    setSelectedFeedback('good')
    clearFeedback()
  }
  function badResponse() {
    sendFeedback({ context: JSON.stringify(chatHistory), feedback: 'bad' })
    setSelectedFeedback('bad')
    clearFeedback()
  }
  function customFeedback(value: string) {
    sendFeedback({ context: JSON.stringify(chatHistory), feedback: value })
    setSelectedFeedback('custom')
    clearFeedback()
  }

  return (
    <Flex
      pos='absolute'
      bottom='0px'
      right={'0px'}
      style={{ transform: 'translate(0,60%)' }}
      bg='white'
      border='1px solid'
      borderColor={'gray.300'}
      borderRadius='md'
    >
      <Tooltip label='Copy answer to clipboard'>
        <Button onClick={copyToClipboard} variant='ghost'>
          {isCopied ? <CheckmarkOutline color='green' /> : <Copy />}
        </Button>
      </Tooltip>
      <Tooltip label='Good response'>
        <Button onClick={goodResponse} variant='ghost'>
          {selectedFeedback === 'good' ? (
            <CheckmarkOutline color='green' />
          ) : (
            <ThumbsUp />
          )}
        </Button>
      </Tooltip>
      <Tooltip label='Bad response'>
        <Button onClick={badResponse} variant='ghost'>
          {selectedFeedback === 'bad' ? (
            <CheckmarkOutline color='green' />
          ) : (
            <ThumbsDown />
          )}
        </Button>
      </Tooltip>
      {selectedFeedback === 'custom' ? (
        <CheckmarkOutline color='green' />
      ) : (
        <TextFeedback sendFeedback={customFeedback} />
      )}
    </Flex>
  )
}

export default FeedbackBar
