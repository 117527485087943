import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { FullPageError } from 'components'
import WarningPopover from '../common/WarningPopover'

import { StreamingWrapper, ThinkingWrapper } from '../common/ThinkingWrapper'
import Header from '../common/Header'
import ChatSection from '../common/ChatSection'
import BottomSection from '../common/BottomSection'
import EmptyScreen from '../common/EmptyScreen'
import useAI from 'api/ai/useAI'
import { assistantsId } from '../common/constants'

const AiReport = ({ reportId }: { reportId: number }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    chatHistory,
    error,
    divRef,
    streamingAnswer,
    search,
    answer,
    handleAnotherQuestion,
    setQuery,
    query,
    assistantId,
  } = useAI(reportId, assistantsId.single_report)

  if (error) return <FullPageError />

  return (
    <>
      <WarningPopover onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Flex
        pos='fixed'
        right={0}
        top='calc(76px + 50px + 18px)'
        mx='2rem'
        w={['300px', '300px', '300px', '300px', '400px', '500px']}
        zIndex={1}
      >
        <Box
          bg='white'
          overflow='auto'
          h='86vh'
          pos='relative'
          border='1px solid'
          borderColor={'gray3'}
          px='1rem'
          borderRadius='6px'
          w='100%'
        >
          <Box flex={1} display='flex' flexDirection='column'>
            <Flex
              flexDir={'column'}
              border={'none'}
              borderColor={'gray3'}
              borderRadius={'6px'}
              mb={0}
              mt={'4px'}
            >
              <Header title='Report AI-Assistant' />
            </Flex>
            <Box
              height={[
                'calc(90vh - 250px)',
                'calc(90vh - 250px)',
                'calc(90vh - 250px)',
                'calc(90vh - 250px)',
              ]}
              overflow='scroll'
              mb='2rem'
              margin={'auto'}
              mt='1rem'
              ref={divRef}
              w='100%'
            >
              {chatHistory.length === 0 && !streamingAnswer && (
                <EmptyScreen
                  description='This question will be answered using this report.'
                  handleSuggestion={(query) => search(query)}
                />
              )}
              {chatHistory.map((message, index) => {
                return (
                  <ChatSection
                    message={message}
                    key={index}
                    chatHistory={chatHistory}
                  />
                )
              })}
              <StreamingWrapper answer={streamingAnswer} />
              <ThinkingWrapper answer={answer} assistantId={assistantId} />
              {chatHistory.length >= 3 && (
                <Flex justifyContent={'center'} mb='1rem' mt='2rem'>
                  <Button variant={'yellow'} onClick={handleAnotherQuestion}>
                    Ask a different question
                  </Button>
                </Flex>
              )}
              <BottomSection
                setQuery={setQuery}
                search={search}
                query={query}
                handleOpenWarningMessage={onOpen}
                containerWidth='95%'
                inputStyle={{ width: '100%', maxWidth: '50vw' }}
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default AiReport
