import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { FullPageError } from 'components'
import WarningPopover from '../common/WarningPopover'
import { StreamingWrapper, ThinkingWrapper } from '../common/ThinkingWrapper'
import Header from '../common/Header'
import ChatSection from '../common/ChatSection'
import BottomSection from '../common/BottomSection'
import EmptyScreen from '../common/EmptyScreen'
import useAI from 'api/ai/useAI'

const AiSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    chatHistory,
    error,
    divRef,
    streamingAnswer,
    search,
    answer,
    handleAnotherQuestion,
    setQuery,
    query,
    assistantId,
  } = useAI()

  if (error) return <FullPageError />

  return (
    <>
      <WarningPopover onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Box overflow='auto' h='85vh'>
        <Box flex={1} display='flex' flexDirection='column'>
          <Flex
            flexDir={'column'}
            border={'none'}
            borderColor={'gray3'}
            borderRadius={'6px'}
            mb={0}
            mt={'4px'}
          >
            <Header
              title={"Biorisk's Reports AI-Search"}
              description='(Reports + Alerts)'
            />
          </Flex>
          <Box
            height={[
              'calc(90vh - 200px)',
              'calc(90vh - 200px)',
              'calc(90vh - 200px)',
              'calc(90vh - 180px)',
            ]}
            overflow='scroll'
            mb='2rem'
            margin={'auto'}
            mt='1rem'
            w='50vw'
            ref={divRef}
          >
            {chatHistory.length === 0 && !streamingAnswer && (
              <EmptyScreen
                description='The question will be answered using Reports and Alerts.'
                exampleQuestions={[
                  'Where are mpox clade Ib cases being detected.',
                  'Is there risk of dengue in the UK?',
                  'Why is mpox clade I dangerous?',
                  'what countries are at the highest risk of disease spillover',
                  // 'Which countries have reported pertussis cases in 2024?',
                  // 'Where is the risk of MERS-CoV the highest?',
                  // 'How many press releases are for Mpox?',
                  // 'How many news are from The telegraph? ',
                  // 'How many Cholera outbreaks happen in Estonia?',
                  // 'Which outbreak disease is the most common in Europe?',
                ]}
                handleSuggestion={(query) => search(query)}
              />
            )}
            {chatHistory.map((message, index) => {
              return (
                <ChatSection
                  message={message}
                  key={index}
                  chatHistory={chatHistory}
                />
              )
            })}
            <StreamingWrapper answer={streamingAnswer} />
            <ThinkingWrapper answer={answer} assistantId={assistantId} />
            {chatHistory.length >= 3 && (
              <Flex justifyContent={'center'} mb='1rem' mt='2rem'>
                <Button variant={'yellow'} onClick={handleAnotherQuestion}>
                  Ask a different question
                </Button>
              </Flex>
            )}
            <BottomSection
              setQuery={setQuery}
              search={search}
              query={query}
              handleOpenWarningMessage={onOpen}
              inputStyle={{ width: '50vw' }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AiSearch
