import { Box, Flex, Text } from '@chakra-ui/react'
import { useMemo, useState } from 'react'

import useSearchEntities from 'api/useSearchEntities'
import { apps, appsListUnion } from 'config/apps'
import { ISingleNavigationPage } from 'interfaces/navigationPage.interface'
import { IModel } from 'interfaces/model.interface'
import { nativeSortTypeFromList } from 'utils/sort'
import EntitySection from 'modules/GlobalSearchOverlay/EntitySection'
import { LoadingAnimation } from 'components'
import { EntitySearchType } from 'api/types'

const ResultsSection = ({
  selectedText,
  app,
  data,
  pageSectionToRender,
}: {
  data: EntitySearchType[] | undefined
  app: appsListUnion
  selectedText: string
  pageSectionToRender: ISingleNavigationPage<any, IModel<any, any>>[]
}) => {
  const [tabSelected, setTabSelected] = useState(0)

  const filteredData = data?.filter(
    (x) => x?.endpoint === pageSectionToRender?.[tabSelected]?.model?.endpoint
  )
  return (
    <Flex
      flexDir={'column'}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Flex gap='8px' my='8px'>
        {pageSectionToRender.map((page, i) => {
          const isSelected = i === tabSelected
          return (
            <Text
              cursor='pointer'
              color={isSelected ? 'black' : 'gray2'}
              textDecor={isSelected ? 'underline' : 'none'}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setTabSelected(i)
              }}
            >
              {page?.model.entityName}
            </Text>
          )
        })}
      </Flex>
      {filteredData && (filteredData?.length || 0) > 0 && (
        <Box pos='relative'>
          <EntitySection
            app={app}
            page={pageSectionToRender[tabSelected]!}
            data={filteredData}
            searchString={selectedText}
            reportView
          />
        </Box>
      )}
    </Flex>
  )
}

const TextTooltip = ({
  app,
  selectedText,
  popoverStyle,
}: {
  app: appsListUnion
  selectedText: string
  popoverStyle: { top?: number; left?: number; display?: string }
}) => {
  console.log('render', selectedText)
  const selectedAppObject = useMemo(() => apps[app], [app])

  const { data, isLoading, error } = useSearchEntities(app, selectedText)

  const appPages = selectedAppObject.pages

  const pageSectionToRender = useMemo(() => {
    if (!data) {
      return []
    }

    const pageList = Array.from(new Set(data.map((x) => x.endpoint)))
      .map((endpoint) =>
        Object.values(appPages).find((page) => page.model.endpoint === endpoint)
      )
      .filter((page) => page !== undefined)
      .filter((page) => page?.path !== null) as ISingleNavigationPage<
      any,
      IModel<any, any>
    >[]

    const initialSortedPageList = pageList.sort((a, b) =>
      ('' + a.model.name).localeCompare(b.model.name)
    )

    const customSortedPageList = selectedAppObject.globalSearchSortModelEndpoint
      ? initialSortedPageList.sort(
          nativeSortTypeFromList(
            selectedAppObject.globalSearchSortModelEndpoint ?? [],
            'model.endpoint'
          )
        )
      : initialSortedPageList
    return customSortedPageList
  }, [data, appPages, selectedAppObject.globalSearchSortModelEndpoint])

  if (!selectedText) return null

  return (
    <Box
      w='400px'
      pos='absolute'
      bg='white'
      border='1px solid'
      borderColor={'gray3'}
      p='8px'
      borderRadius='4px'
      zIndex={10}
      top={popoverStyle?.top}
      left={popoverStyle?.left ? popoverStyle?.left - 200 : 0}
      display={popoverStyle.display}
      boxShadow={'xl'}
    >
      {isLoading && (
        <Flex
          h='50px'
          justifyContent={'center'}
          alignItems={'center'}
          p='3rem'
          mx='1rem'
          flexDir={'column'}
        >
          <Flex w='50%' ml='-1rem'>
            <LoadingAnimation height={'50px'} />
          </Flex>
          <Text mt='8px'>Searching on the DB.</Text>
        </Flex>
      )}

      <ResultsSection
        data={data}
        pageSectionToRender={pageSectionToRender}
        selectedText={selectedText}
        app={app}
      />
      {!isLoading && !error && (data?.length || 0) === 0 && (
        <Box>No matches for {selectedText}</Box>
      )}
    </Box>
  )
}

export default TextTooltip
