import { Box } from '@chakra-ui/react'
import { createRef, useEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { useAppParams } from 'routes/utils'
import useTracking from 'tracking/useTracking'
import useIsMobile from 'utils/useIsMobile'
import TextTooltip from './TextTooltip'

type PdfViewerProps = {
  reportUrl: string
  reportId?: string
  reportTitle?: string
  reportType?: string
}

export const PdfViewer = ({
  reportUrl,
  reportId,
  reportTitle,
  reportType,
}: PdfViewerProps) => {
  const pageRefs = useRef<any[]>([])
  const [windowWidth, setWindowWidth] = useState<number>(400)
  const [numPages, setNumPages] = useState<number>()

  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()
  const { app, pageSlug } = useAppParams()

  const [selectedText, setSelectedText] = useState('')
  const [popoverStyle, setPopoverStyle] = useState({})
  const pdfContainerRef = useRef()

  const handleMouseUp = () => {
    const selection = window.getSelection() as any
    const text = selection.toString().trim()

    if (text) {
      setSelectedText(text)

      // Get the bounding rectangle of the selected text
      const range = selection.getRangeAt(0)
      const rect = range.getBoundingClientRect()

      // Calculate the popover position relative to the scrollable container
      const containerRect = (
        pdfContainerRef as any
      ).current.getBoundingClientRect()
      const scrollTop = (pdfContainerRef as any).current.scrollTop

      setPopoverStyle({
        display: 'block',
        top: rect.top - containerRect.top + scrollTop + 20, // Adjust for scrollable container
        left: rect.left - containerRect.left,
      })
    } else {
      setPopoverStyle({ display: 'none' }) // Hide popover if no text selected
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
    pageRefs.current = new Array(numPages).fill(null).map(() => createRef())
  }
  function onDocumentLoadError(error: Error): void {
    console.error('Error loading PDF:', error)
    // Handle error state
  }

  function handleClick({ pageNumber }: { pageNumber: number }) {
    // tracking.clickedOnPdfIndex({
    //   app,
    //   page: pageSlug,
    //   reportId: reportId ?? '',
    //   type: reportType ?? '',
    //   report: reportTitle ?? '',
    // })
    const targetRef = pageRefs.current[pageNumber - 1]
    if (targetRef?.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  console.log({ popoverStyle })

  const pages = new Array(numPages).fill(1)

  const width = windowWidth / (isMobile ? 1.1 : 1.77)

  return (
    <Box ref={pdfContainerRef as any} pos='relative' zIndex={999}>
      <Document
        onMouseUp={handleMouseUp}
        className='pdf-document'
        file={reportUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        onItemClick={handleClick}
        externalLinkTarget='_blank'
        externalLinkRel='noopener noreferrer'
        loading={() => <Box h={width / 1.77} bg='gray.300' width={width} />}
      >
        <Box p='4px'>
          {pages.map((_, index) => {
            return (
              <Box key={index} py='8px' ref={pageRefs.current[index]}>
                <Page
                  pageNumber={index + 1}
                  width={width}
                  loading={() => (
                    <Box h={width / 1.77} bg='gray.300' width={width} />
                  )}
                />
              </Box>
            )
          })}
        </Box>
      </Document>
      <TextTooltip
        app={app}
        selectedText={selectedText}
        popoverStyle={popoverStyle}
      />
    </Box>
  )
}

export default PdfViewer
