import { Download, Launch } from '@carbon/icons-react'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import html from 'rehype-parse'
// @ts-ignore
import rehypeTruncate from 'rehype-truncate'

import { Report } from 'api/types'

import { formatUtc } from 'utils/formatDate'

import UpSellSection from './UpSell'
import { useNavigate } from 'react-router-dom'
import { useAppParams } from 'routes/utils'
import resolvePath from 'utils/resolvePath'
import useTracking from 'tracking/useTracking'
import { DownloadPdfReport, ViewPdfReport } from 'components'
import _ from 'lodash'
import { useContext } from 'react'
import { AuthContext } from 'contexts'

type TriggerPropTypes = {
  handleClick: () => void
  children: React.ReactNode
}
const Trigger = ({ handleClick, children }: TriggerPropTypes) => {
  return (
    <Flex
      cursor='pointer'
      border='1px solid'
      alignItems='center'
      justifyContent='center'
      borderRadius='full'
      width='22px'
      height='22px'
      onClick={handleClick}
    >
      {children}
    </Flex>
  )
}

type MiniCardPropTypes = {
  report: Report
  onClick: () => void
  type?: string
  isMobile?: boolean
}
const MiniCard = ({
  report,
  onClick,
  type = 'Report',
  isMobile = false,
}: MiniCardPropTypes) => {
  const { app, pageSlug, subPageSlug } = useAppParams()
  const [tracking] = useTracking()
  const navigate = useNavigate()
  const { userCanAccessBetaFeatures } = useContext(AuthContext)

  const title = report.title
  const date = new Date(report.date)
  const content = report.content
  const url = report.report

  const handleReportNavigation = () => {
    tracking.navigateToSecureReport({
      app,
      type,
      report: title,
      reportId: report.id,
    })

    const path = resolvePath([app, pageSlug, subPageSlug, report.id])
    navigate(`${path}?type=${type}`)
  }

  return (
    <Box>
      <Flex cursor='pointer' onClick={onClick} flexDir='column'>
        <Box>
          <Flex
            mb={2}
            justifyContent='space-between'
            flexDir={isMobile ? 'column' : 'row'}
            alignItems='flex-start'
          >
            <Text fontSize={'sm'} variant='body-bold'>
              {title}
            </Text>
            <Text color='gray1' variant='body3-bold' whiteSpace={'nowrap'}>
              {formatUtc(date, 'dd MMM yyyy')}
            </Text>
          </Flex>
          <Box
            maxW='480px'
            color='gray1'
            fontWeight={400}
            fontSize='14px'
            lineHeight='22px'
          >
            <ReactMarkdown rehypePlugins={[html, rehypeTruncate]}>
              {content}
            </ReactMarkdown>
          </Box>
        </Box>
      </Flex>
      <Flex gap={3} mt={4} onClick={(e) => e.stopPropagation()}>
        {url ? (
          userCanAccessBetaFeatures ? (
            <Trigger handleClick={handleReportNavigation}>
              <Icon w='13px' height='13px' as={Launch} />
            </Trigger>
          ) : (
            <>
              <DownloadPdfReport
                triggerComponent={({ handleClick }) => (
                  <Trigger handleClick={handleClick}>
                    <Icon w='14px' as={Download} />
                  </Trigger>
                )}
                url={url}
                title={title}
                onDownload={() => {
                  ;(tracking as any)['download' + type]({
                    [_.lowerFirst(type)]: title,
                  })
                }}
              />
              <ViewPdfReport
                triggerComponent={({ handleClick }) => (
                  <Trigger handleClick={handleClick}>
                    <Icon w='13px' height='13px' as={Launch} />
                  </Trigger>
                )}
                url={url}
                onView={() => {
                  ;(tracking as any)['open' + type]({
                    [_.lowerFirst(type)]: title,
                  })
                }}
              />
            </>
          )
        ) : (
          <UpSellSection
            details={`User wanted to access ${report.title} from ${window.location.href}`}
            title={title}
          />
        )}
      </Flex>
    </Box>
  )
}

export default MiniCard
