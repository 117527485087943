import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { memo, useContext } from 'react'

import { Report } from 'api/types'

import useTracking from 'tracking/useTracking'
import { useNavigate } from 'react-router-dom'
import { useAppParams } from 'routes/utils'
import resolvePath from 'utils/resolvePath'
import { DownloadPdfReport, ViewPdfReport } from 'components'
import { Download } from '@carbon/icons-react'
import { AuthContext } from 'contexts'

function ReportViewOrDownload({ report }: { report: Report }) {
  const navigate = useNavigate()
  const { app, pageSlug, subPageSlug } = useAppParams()
  const { userCanAccessBetaFeatures } = useContext(AuthContext)
  const [tracking] = useTracking()
  if (!report) {
    return null
  }

  const handleReportNavigation = () => {
    //TODO: add tracking
    const path = resolvePath([app, pageSlug, subPageSlug, report.id])
    navigate(`${path}`)
  }

  return (
    <Box borderBottomRadius='8px' bg='gray.50'>
      <Flex justifyContent='flex-end' px={5} alignItems='center'>
        {userCanAccessBetaFeatures ? (
          <Button onClick={handleReportNavigation} variant='outline' my='4px'>
            View Report
          </Button>
        ) : (
          <>
            <DownloadPdfReport
              url={report.report}
              title={report.title}
              onDownload={() => {
                tracking.downloadReport({
                  report: report.title,
                })
              }}
              triggerComponent={({ isLoading, disabled, handleClick }) => (
                <Flex
                  fontWeight={500}
                  fontSize='sm'
                  lineHeight='none'
                  alignItems='center'
                  {...(isLoading
                    ? {
                        textDecor: 'none',
                        cursor: 'default',
                        _hover: { textDecor: 'none' },
                      }
                    : {})}
                >
                  <Box
                    py={4}
                    borderTop='1px solid'
                    borderColor='gray.100'
                    display='flex'
                    alignItems='center'
                  >
                    <Box
                      width='32px'
                      height='32px'
                      mr={2}
                      display='flex'
                      bg='white'
                      rounded='full'
                      shadow='sm'
                      alignItems='center'
                      justifyContent='center'
                      as='button'
                      onClick={handleClick}
                      disabled={disabled}
                    >
                      <Download size={20} />
                    </Box>
                    <Text
                      fontWeight={500}
                      fontSize='sm'
                      lineHeight='none'
                      pr={2}
                    >
                      {report.title}
                    </Text>
                  </Box>
                  {isLoading && <Spinner ml={2} />}
                </Flex>
              )}
            />
            <ViewPdfReport
              url={report.report}
              onView={() => {
                tracking.openReport({
                  report: report.title,
                })
              }}
            />{' '}
          </>
        )}
      </Flex>
    </Box>
  )
}

export default memo(ReportViewOrDownload)
