import { Box, Button, Flex, Grid, Icon, Text } from '@chakra-ui/react'

import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { useSingleReportById } from 'api/cms/reports/useReports'
import { useAppParams } from 'routes/utils'
import { DownloadPdfReport, LoadingAnimation } from 'components'

import './styles.css'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useAxios from 'api/useAxios'
import ShareEmailPopover from './ShareEmailPopover'
import PdfViewer from './PdfViewer'
import useIsMobile from 'utils/useIsMobile'
import { NAV_HEIGHT } from 'constants/misc'
import { Download } from '@carbon/icons-react'
import useTracking from 'tracking/useTracking'
import resolvePath from 'utils/resolvePath'
import _ from 'lodash'
import AiReport from '../ai/ai-report'

const DownloadPDF = ({
  pdfUrl,
  title,
  type,
}: {
  pdfUrl: string
  title: string
  type: string
}) => {
  const [tracking] = useTracking()
  return (
    <DownloadPdfReport
      triggerComponent={({ handleClick }) => (
        <Button
          variant={'yellow'}
          onClick={handleClick}
          cursor='pointer'
          alignItems='center'
          gap={2}
        >
          <Flex mb='1px'>
            <Icon w='14px' as={Download} />
          </Flex>
          <Text variant='body3'>Download PDF</Text>
        </Button>
      )}
      url={pdfUrl}
      title={title}
      onDownload={() => {
        tracking.downloadReport({
          report: title,
          type,
        })
      }}
    />
  )
}

const SecureReport = () => {
  const axios = useAxios()
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const params = useParams()
  const { pageSlug, app, subPageSlug } = useAppParams()
  const [tracking] = useTracking()

  const pdfType = queryParams.get('type') || ''
  const reportId = params.id || ''
  const { data: reportData } = useSingleReportById(app, reportId, pdfType)

  const pdfUrl = reportData?.report || reportData?.pdf

  const [isMobile] = useIsMobile()

  function handleGoBack() {
    if (!subPageSlug) navigate(resolvePath([app]))
    else {
      const isNumber = _.isNumber(+subPageSlug)
      navigate(resolvePath([app, pageSlug, isNumber ? '' : subPageSlug]))
    }
  }

  async function handleShareReport(email: string) {
    tracking.shareSecureReport({
      app,
      page: pageSlug,
      reportId,
      report: reportData?.title ?? '',
      type: pdfType,
      to: email,
    })
    try {
      await axios.post(
        `/report-sharing/?email_address=${encodeURI(email)}&report_id=${reportId}`
      )
      return true
    } catch (err) {
      console.error('Sharing report error', err)
      return false
    }
  }

  return (
    <Flex
      justifyContent={'flex-start'}
      alignItems='center'
      h={`calc(100vh - ${NAV_HEIGHT})`}
      flexDir={'column'}
      bg='gray.100'
      py='0.5rem'
      pos={'relative'}
    >
      <Flex
        flexDir={isMobile ? 'column' : 'row'}
        textAlign='center'
        alignItems={'center'}
        justifyContent='center'
        w='100%'
        p='1rem 3rem'
        pos='relative'
      >
        <Box
          left='2rem'
          top='1rem'
          display='flex'
          fontSize='sm'
          fontWeight={500}
          color='gray.700'
          _hover={{
            textDecoration: 'underline',
          }}
          cursor='pointer'
          onClick={handleGoBack}
          pos='absolute'
        >
          ← Back
        </Box>

        <Text mb='8px' fontSize={'2xl'} fontWeight='bold'>
          {reportData?.title}
        </Text>
        <Flex gap='1rem' flexDir={'row'} pos='absolute' right='1rem' top='1rem'>
          <ShareEmailPopover handleClick={handleShareReport} />
          {pdfUrl && (
            <DownloadPDF
              pdfUrl={pdfUrl}
              title={reportData?.title ?? ''}
              type={pdfType}
            />
          )}
        </Flex>
      </Flex>
      <Flex overflowY={'scroll'} overflowX={'visible'} w='100%'>
        {!pdfUrl ? (
          <LoadingAnimation backgroundColor='#f6f6f6' />
        ) : (
          <Grid pos='relative' templateColumns={'5fr 2fr'} gap='1rem' px='1rem'>
            <PdfViewer
              reportUrl={pdfUrl}
              reportId={reportId}
              reportTitle={reportData?.title ?? ''}
              reportType={pdfType}
            />
            <AiReport reportId={+reportId} />
          </Grid>
        )}
      </Flex>
    </Flex>
  )
}

export default SecureReport
