import { Cognitive, MachineLearning } from '@carbon/icons-react'
import { Box, Flex, Text } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { assistantsId } from '../common/constants'

const Loader = () => {
  return <span className='loader' />
}

const ThinkingWrapper = ({
  answer,
  assistantId,
}: {
  answer: string
  assistantId: string
}) => {
  if (!answer) return null
  const isInternal = assistantId === assistantsId.router
  return (
    <>
      {isInternal && (
        <Text color='gray.800' as='i' fontSize='md' ml='1rem'>
          Internal Reasoning
        </Text>
      )}
      <Flex
        p='1rem'
        flexDir={'row'}
        alignItems={'left'}
        justifyContent='flex-start'
        border={isInternal ? '' : '1px solid'}
        borderRadius={'12px'}
        borderColor={'#e3e2df'}
        bgGradient={
          isInternal
            ? ''
            : 'linear-gradient(rgba(53, 27, 97, 0.04), rgba(53, 27, 97, 0))'
        }
      >
        <Flex textTransform='capitalize' alignItems={'center'} mr='1rem'>
          <Box bg='#FFED00' p='4px' borderRadius={'6px'}>
            {isInternal ? (
              <Cognitive color='#0B0B0B' size={'18px'} />
            ) : (
              <MachineLearning color='#0B0B0B' size={'18px'} />
            )}
          </Box>
        </Flex>
        <Flex gap='2rem'>
          {isInternal && 'Thinking'}
          <Loader />
        </Flex>
      </Flex>
    </>
  )
}
const StreamingWrapper = ({ answer }: { answer: string }) => {
  if (!answer) return null
  const parsedAnswer = answer.replace(/【.*?】/g, '')

  return (
    <Flex
      gap='8px'
      flexDir={'column'}
      mb={'2rem'}
      w={'fit-content'}
      p='1rem'
      ml={''}
      mr={'auto'}
      bg={''}
      border={'1px solid'}
      borderRadius={'12px'}
      borderColor={'#e3e2df'}
      bgGradient={
        'linear-gradient(rgba(53, 27, 97, 0.04), rgba(53, 27, 97, 0))'
      }
    >
      <Flex
        minW={'80px'}
        gap='16px'
        alignItems='flex-start'
        _firstLetter={{
          textTransform: 'uppercase',
        }}
      >
        <Box bg={'#FFED00'} p='4px' borderRadius={'6px'} fontWeight={'bold'}>
          <MachineLearning color='#0B0B0B' size={'18px'} />
        </Box>

        <ReactMarkdown rehypePlugins={[rehypeRaw]} className='ai markdown'>
          {parsedAnswer}
        </ReactMarkdown>
      </Flex>
    </Flex>
  )
}

export { StreamingWrapper, ThinkingWrapper }
