import { Box, Flex, Text, Image, Grid } from '@chakra-ui/react'

import AiLogo from 'components/Logo/airfinity-intelligence.svg'

const SuggestedQuestionCard = ({
  question,
  onClick,
}: {
  question: string
  onClick: (question: string) => void
}) => {
  return (
    <Box
      border='1px solid'
      borderColor='gray.200'
      rounded='md'
      p='12px'
      bg='gray.100'
      fontSize={'sm'}
      _hover={{
        bg: 'gray.50',
      }}
      cursor='pointer'
      onClick={() => onClick(question)}
    >
      {question}
    </Box>
  )
}

const EmptyScreen = ({
  handleSuggestion,
  exampleQuestions = [],
  description = 'This question will be answered using this report.',
}: {
  handleSuggestion: (question: string) => void
  exampleQuestions?: string[]
  description?: string
}) => {
  return (
    <Box
      h='100%'
      display={'flex'}
      flexDir={'column'}
      justifyContent='space-between'
      w='100%'
      bg='transparent'
    >
      <Box
        h='100%'
        display={'flex'}
        justifyContent='center'
        alignItems='center'
        flexDir={'column'}
        gap='1rem'
      >
        <Image src={AiLogo} w='200px' />
        <Text fontSize={'xl'} fontWeight={'semibold'}>
          How can I help you today?
        </Text>
        <Flex alignItems={'center'} flexDir={'column'}>
          <Text color={'gray.600'} as='i'>
            {description}
          </Text>
        </Flex>
      </Box>
      {exampleQuestions.length > 0 ? (
        <Grid gridTemplateColumns={'1fr 1fr'} gap='0.5rem' mb='1rem' mx='8px'>
          {exampleQuestions.map((exampleQuestion, index) => {
            return (
              <SuggestedQuestionCard
                question={exampleQuestion}
                onClick={handleSuggestion}
                key={index}
              />
            )
          })}
        </Grid>
      ) : null}
    </Box>
  )
}

export default EmptyScreen
