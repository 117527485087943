import { IApp } from 'interfaces/navigationApp.interface'

import BioriskApp from './apps/Biorisk/Custom/Biorisk/biorisk'
import CardioAntithrombotics from './apps/Cardio/Science360/CardioAntithrombotics/cardioAntithrombotics'
import CardioLipidApp from './apps/Cardio/Science360/LipidLoweringTherapies/cardioLipid'
import CardioConferenceApp from './apps/CardioConference/Custom/ConferenceReports/cardioConference'
import CovidCommercialTherapeuticsApp from './apps/Covid19/Commercial/Therapeutics/Covid19CommercialTherapeuticsApp'
import CovidCommercialVaccinesApp from './apps/Covid19/Commercial/Vaccines/Covid19CommercialVaccinesApp'
import EpidemiologyApp from './apps/Covid19/Epidemiology/epidemiology'
import TreatmentsApp from './apps/Covid19/Science360/Therapeutics/treatments'
import VaccinesApp from './apps/Covid19/Science360/Vaccines/vaccines'
import CovidEpidDataApp from './apps/Covid19Epid/Custom/CovidEpidData/covidEpiData'
import DiagnosticsApp from './apps/Diagnostics/Science360/Covid19Diagnostics/diagnostics'
import CovidVaccineMarketForecast from './apps/GSKCovid19BoosterDemandForecast/Custom/GSKCovid19BoosterDemandForecast/covidVaccineMarket'
import IDA360App from './apps/IDA360/Custom/IDA360/IDA360App'
import IEGPolicySurveillance from './apps/IEGPolicySurveillance/Custom/IEGPolicySurveillance/IEGPolicySurveillanceApp'
import InfluenzaCommercialApp from './apps/Influenza/Commercial/VaccinesTherapeutics/influenzaCommercialApp'
import InfluenzaEpidemiology from './apps/Influenza/Epidemiology/Epidemiology/influenzaEpidemiology'
import InfluenzaApp from './apps/Influenza/Science360/VaccinesTherapeutics/influenza'
import InternalToolingApp from './apps/Internal/InternalToolingApp'
import ModernaCovid19CommercialApp from './apps/Moderna/Commercial/Covid19/ModernaCovid19CommercialApp'
import ModernaInfluenzaCommercialApp from './apps/Moderna/Commercial/Influenza/ModernaInfluenzaCommercialApp'
import ModernaRSVCommercialApp from './apps/Moderna/Commercial/RSV/ModernaRSVCommercialApp'
import ModernaCovid19EpidemiologyApp from './apps/Moderna/Epidemiology/Covid19/ModernaCovid19EpidemiologyApp'
import ModernaInfluenzaEpidemiologyApp from './apps/Moderna/Epidemiology/Influenza/ModernaInfluenzaEpidemiologyApp'
import ModernaRSVEpidemiologyApp from './apps/Moderna/Epidemiology/RSV/ModernaRSVEpidemiologyApp'
import ModernaCovid19Science360App from './apps/Moderna/Science360/Covid19/ModernaCovid19Science360App'
import ModernaInfluenzaScience360App from './apps/Moderna/Science360/Influenza/ModernaInfluenzaScience360App'
import ModernaRSVScience360App from './apps/Moderna/Science360/RSV/ModernaRSVScience360App'
import ObesityCommercial from './apps/Obesity/Commercial/ObesityCommercial'
import ObesityEpidemiology from './apps/Obesity/Epidemiology/ObesityEpidemiology'
import Obesity from './apps/Obesity/Obesity360/obesity'
import ObesityPredictiveAnalytics from './apps/Obesity/PredictiveAnalytics/PredictiveAnalytics'
import Pad from './apps/PAD/pad'
import PNHApp from './apps/PNH/Custom/PNH/PNHApp'
import RNATherapeuticsApp from './apps/RNATherapeutics/Custom/RNATherapeutics/RNATherapeuticsApp'
import RSVCommercialApp from './apps/RSV/Commercial/RSVCommercialApp'
import RSVEpidemiologyApp from './apps/RSV/Epidemiology/RSVEpidemiologyApp'
import RSVApp from './apps/RSV/Science360/VaccinesTherapeutics/rsv'
import ResearchPowerIndexApp from './apps/ResearchPowerIndex/Custom/ResearchPowerIndex/ResearchPowerIndexApp'

export const appsList = [
  'covid-19-vaccines',
  'covid-19-therapeutics',
  'biorisk',
  'covid-19-commercial-therapeutics',
  'covid-19-commercial-vaccines',
  'epidemiology',
  'influenza',
  'influenza-commercial',
  'influenza-epidemiology',
  'rsv',
  'rsv-epidemiology',
  'rsv-commercial',
  'covid-epid-data',
  'cardio-lipid',
  'covid-diagnostics',
  'booster-demand-forecast',
  'conference-data',
  'ieg-policy-surveillance',
  'research-power-index',
  'internal-tooling',
  'cardio-antithrombotics',
  'pad',
  'moderna-covid-19-commercial',
  'moderna-covid-19-epidemiology',
  'moderna-covid-19-science360',
  'moderna-influenza-commercial',
  'moderna-influenza-epidemiology',
  'moderna-influenza-science360',
  'moderna-rsv-epidemiology',
  'moderna-rsv-science360',
  'moderna-rsv-commercial',
  'rna',
  'pnh',
  'ida-360',
  'obesity-360',
  'obesity-commercial',
  'obesity-epidemiology',
  'obesity-predictive-analytics',
] as const

export const premiumEndpoints = ['ida-premium', 'ida'] as const

export type appsListUnion = (typeof appsList)[number]
export type PremiumEndpointsTypes = (typeof premiumEndpoints)[number]

export const apps: Record<appsListUnion, IApp<any>> = {
  /* -------------------------------------------------------------------------- */
  /*                                  COVID-19                                  */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Science360 ------------------------------- */
  'covid-19-vaccines': VaccinesApp,
  'covid-19-therapeutics': TreatmentsApp,
  /* -------------------------------- Pandemic -------------------------------- */
  biorisk: BioriskApp,
  /* ------------------------------  Commercial ------------------------------- */
  'covid-19-commercial-therapeutics': CovidCommercialTherapeuticsApp,
  'covid-19-commercial-vaccines': CovidCommercialVaccinesApp,
  /* ------------------------------ Epidemiology ------------------------------ */
  epidemiology: EpidemiologyApp,

  /* -------------------------------------------------------------------------- */
  /*                                  INFLUENZA                                 */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Science360 ------------------------------- */
  influenza: InfluenzaApp,
  /* ------------------------------ Commercial -------------------------------- */
  'influenza-commercial': InfluenzaCommercialApp,
  /* ------------------------------ Epidemiology ------------------------------ */
  'influenza-epidemiology': InfluenzaEpidemiology,

  /* -------------------------------------------------------------------------- */
  /*                                     RSV                                    */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Science360 ------------------------------- */
  rsv: RSVApp,
  /* ------------------------------- Epidemiology ----------------------------- */
  'rsv-epidemiology': RSVEpidemiologyApp,
  /* ------------------------------- Market & Production ---------------------- */
  'rsv-commercial': RSVCommercialApp,

  /* -------------------------------------------------------------------------- */
  /*                                COVID-19 Epid                               */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  'covid-epid-data': CovidEpidDataApp,

  /* -------------------------------------------------------------------------- */
  /*                                   Cardio                                   */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Science360 ------------------------------- */
  'cardio-lipid': CardioLipidApp,
  'cardio-antithrombotics': CardioAntithrombotics,

  /* -------------------------------------------------------------------------- */
  /*                                 Diagnostics                                */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Science360 ------------------------------- */
  'covid-diagnostics': DiagnosticsApp,

  /* -------------------------------------------------------------------------- */
  /*                                  Moderna                                   */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ Commercial -------------------------------- */
  'moderna-covid-19-commercial': ModernaCovid19CommercialApp,
  'moderna-influenza-commercial': ModernaInfluenzaCommercialApp,
  'moderna-rsv-commercial': ModernaRSVCommercialApp,
  /* ---------------------------- Epidemiology -------------------------------- */
  'moderna-covid-19-epidemiology': ModernaCovid19EpidemiologyApp,
  'moderna-influenza-epidemiology': ModernaInfluenzaEpidemiologyApp,
  'moderna-rsv-epidemiology': ModernaRSVEpidemiologyApp,
  /* ---------------------------- Science360 --------------------------------- */
  'moderna-covid-19-science360': ModernaCovid19Science360App,
  'moderna-influenza-science360': ModernaInfluenzaScience360App,
  'moderna-rsv-science360': ModernaRSVScience360App,

  /* -------------------------------------------------------------------------- */
  /*                    GSK COVID-19 Booster Demand Forecast                    */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  'booster-demand-forecast': CovidVaccineMarketForecast,

  /* -------------------------------------------------------------------------- */
  /*                          Cardio Conference Reports                         */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  'conference-data': CardioConferenceApp,

  /* -------------------------------------------------------------------------- */
  /*                          IEG Policy Surveillance                           */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  'ieg-policy-surveillance': IEGPolicySurveillance,

  /* -------------------------------------------------------------------------- */
  /*                            Research Power Index                            */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  'research-power-index': ResearchPowerIndexApp,
  /* -------------------------------------------------------------------------- */
  /*                                  PAD                                       */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  pad: Pad,
  /* -------------------------------------------------------------------------- */
  /*                              Internal Tooling                              */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  'internal-tooling': InternalToolingApp,
  /* -------------------------------------------------------------------------- */
  /*                              RNA Therapeutics                              */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  rna: RNATherapeuticsApp,
  /* -------------------------------------------------------------------------- */
  /*                                    PNH                                     */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  pnh: PNHApp,
  /* -------------------------------------------------------------------------- */
  /*                                   IDA360                                   */
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- Custom --------------------------------- */
  'ida-360': IDA360App,
  /* -------------------------------------------------------------------------- */
  /*                                  Obesity                                   */
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Science360 ------------------------------- */
  'obesity-360': Obesity,
  'obesity-commercial': ObesityCommercial,
  'obesity-epidemiology': ObesityEpidemiology,
  'obesity-predictive-analytics': ObesityPredictiveAnalytics,
}
