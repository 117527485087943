import { Download, Launch } from '@carbon/icons-react'
import {
  Box,
  CloseButton,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { Report } from 'api/types'

import useTracking from 'tracking/useTracking'

import { formatUtc } from 'utils/formatDate'

import UpSellSection from './UpSell'
import { useNavigate } from 'react-router-dom'
import { useAppParams } from 'routes/utils'
import resolvePath from 'utils/resolvePath'
import { DownloadPdfReport, ViewPdfReport } from 'components'
import { useContext } from 'react'
import { AuthContext } from 'contexts'
import _ from 'lodash'

interface SlideOverModalPropTypes {
  report: Report
  handleClose: () => void
  type?: string
}

const SlideOverModal = ({
  handleClose,
  report,
  type = 'Report',
}: SlideOverModalPropTypes) => {
  const { app, pageSlug, subPageSlug } = useAppParams()
  const { userCanAccessBetaFeatures } = useContext(AuthContext)
  const navigate = useNavigate()
  const [tracking] = useTracking()

  const handleReportNavigation = () => {
    //TODO: add tracking
    const path = resolvePath([app, pageSlug, subPageSlug, report.id])
    navigate(`${path}?type=${type}`)
  }

  const title = report.title
  const content = report.content
  const url = report.report
  const date = formatUtc(new Date(report.date), 'dd MMMM yyyy')

  return (
    <>
      <Box w='full'>
        <Box bg='gray4' px={8} py={5}>
          <CloseButton
            onClick={handleClose}
            position='absolute'
            right={5}
            top={3}
            _hover={{ bg: 'none' }}
          />
          <Box maxW='2xl' mx='auto'>
            <Text variant='body3-bold' mb={1.5}>
              Report
            </Text>
            <Heading variant='h3'>{title}</Heading>
            <Text variant='body' mt={2}>
              {date}
            </Text>
          </Box>
        </Box>
        {url ? (
          userCanAccessBetaFeatures ? (
            <HStack
              px={8}
              gap={4}
              py={4}
              borderWidth='1px 0px 1px 0px'
              borderColor='gray3'
              bg='white'
            >
              <Flex
                onClick={handleReportNavigation}
                cursor='pointer'
                alignItems='center'
                gap={2}
              >
                <Box
                  border='1px solid'
                  w='22px'
                  height='22px'
                  textAlign='center'
                  borderRadius='full'
                >
                  <Icon w='14px' as={Launch} />
                </Box>
                <Text variant='body3'>Read Report</Text>
              </Flex>
            </HStack>
          ) : (
            <HStack
              px={8}
              gap={4}
              py={4}
              borderWidth='1px 0px 1px 0px'
              borderColor='gray3'
              bg='white'
            >
              <DownloadPdfReport
                triggerComponent={({ handleClick }) => (
                  <Flex
                    onClick={handleClick}
                    cursor='pointer'
                    alignItems='center'
                    gap={2}
                  >
                    <Box
                      border='1px solid'
                      w='22px'
                      height='22px'
                      textAlign='center'
                      borderRadius='full'
                    >
                      <Icon w='14px' as={Download} />
                    </Box>
                    <Text variant='body3'>Download PDF</Text>
                  </Flex>
                )}
                url={url}
                title={title}
                onDownload={() => {
                  ;(tracking as any)['download' + type]({
                    [_.lowerFirst(type)]: title,
                  })
                }}
              />
              <ViewPdfReport
                triggerComponent={({ handleClick }) => (
                  <Flex
                    cursor='pointer'
                    onClick={handleClick}
                    alignItems='center'
                    gap={2}
                  >
                    <Box
                      border='1px solid'
                      w='22px'
                      height='22px'
                      textAlign='center'
                      borderRadius='full'
                    >
                      <Icon w='12px' as={Launch} />
                    </Box>
                    <Text variant='body3'>Open PDF</Text>
                  </Flex>
                )}
                url={url}
                onView={() => {
                  ;(tracking as any)['open' + type]({
                    [_.lowerFirst(type)]: title,
                  })
                }}
              />
            </HStack>
          )
        ) : (
          <HStack
            px={8}
            gap={4}
            py={4}
            borderWidth='1px 0px 1px 0px'
            borderColor='gray3'
            bg='white'
          >
            <UpSellSection
              details={`User wanted to access ${report.title} from ${window.location.href}`}
              title={title}
            />
          </HStack>
        )}
      </Box>
      <Box marginTop='1rem' overflow='scroll' px={8} py={6}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>

        {userCanAccessBetaFeatures ? (
          <Text
            cursor='pointer'
            display='inline-block'
            fontSize='13px'
            lineHeight='20px'
            letterSpacing='0.01em'
            textDecoration='underline'
            color='data6'
            fontWeight={500}
            mt={5}
            onClick={handleReportNavigation}
          >
            Read More
          </Text>
        ) : (
          <ViewPdfReport
            triggerComponent={({ handleClick }) => (
              <Text
                cursor='pointer'
                display='inline-block'
                fontSize='13px'
                lineHeight='20px'
                letterSpacing='0.01em'
                textDecoration='underline'
                color='data6'
                fontWeight={500}
                mt={5}
                onClick={handleClick}
              >
                Read More
              </Text>
            )}
            url={url}
            onView={() => {
              ;(tracking as any)['open' + type]({
                [_.lowerFirst(type)]: title,
              })
            }}
          />
        )}
      </Box>
    </>
  )
}

export default SlideOverModal
